import * as React from 'react';

import Ingredient from 'cls/Ingredient';
import IngredientsList from 'cls/IngredientsList';
import { N } from 'cls/N';
import Quantity from 'cls/Quantity';
import Recipe from 'cls/Recipe';
import { Price as ThriftPrice } from 'thriftgen';

export default class Price {
  kcal: number;
  keto: number;

  constructor(x?: ThriftPrice) {
    this.keto = x?.keto !== undefined && !isNaN(x.keto) && x.keto !== 0 ? x.keto : 0;
    this.kcal = x?.kcal !== undefined && !isNaN(x.kcal) && x.kcal !== 0 ? x.kcal : 0;
  }

  get thrift(): ThriftPrice {
    return new ThriftPrice({
      keto: this.keto,
      kcal: this.kcal,
    });
  }

  copy(): Price {
    return new Price(this.thrift);
  }

  print(priceType: string, quantity?: Quantity, item?: Ingredient | Recipe | undefined) {

    if (priceType === 'keto') {
      const priceStr = this.keto + '';
      return <>
        <small><i className="czi-diamond"/></small>&nbsp;
        { priceStr }
      </>;
    }

    if (quantity === undefined) {
      const priceStr = (this.kcal) + '';
      return <>
        { priceStr } { process.env.REACT_APP_KCAL }
      </>;
    }

    let rate = 1;
    if (item instanceof Ingredient) {
      rate = N.getRate(quantity.q, item.portions, item.id) || 100;
    }
    if (item instanceof Recipe) {
      rate = IngredientsList.getRecipeMultiplier(quantity.q, item.serving, item.count, item.id);
    }

    const priceStr = Math.round(this.kcal * rate) + '';
    return <>
      { priceStr } { process.env.REACT_APP_KCAL }
    </>;
  }
}
