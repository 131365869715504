import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import Urls from 'cls/Urls';

export const MainLinks = () => {
  const location = useLocation();
  const path = location.pathname;
  return <>
    <LinkContainer to={ Urls.MENU }>
      <Nav.Link
        href={ Urls.MENU }
        className={ `${ path === Urls.MENU ? 'active' : '' }` }
      >
        { process.env.REACT_APP_MENU }
      </Nav.Link>
    </LinkContainer>
    <LinkContainer to={ Urls.SHOPPING_LISTS }>
      <Nav.Link
        href={ Urls.SHOPPING_LISTS }
        className={ `${ path === Urls.SHOPPING_LISTS ? 'active' : '' }` }
      >
        { process.env.REACT_APP_SHOPPING }
      </Nav.Link>
    </LinkContainer>
    <LinkContainer to={ Urls.RECIPES }>
      <Nav.Link
        href={ Urls.RECIPES }
        className={ `${ path === Urls.RECIPES ? 'active' : '' }` }
      >
        { process.env.REACT_APP_RECIPES }
      </Nav.Link>
    </LinkContainer>
    <LinkContainer to={ Urls.INGREDIENTS }>
      <Nav.Link
        href={ Urls.INGREDIENTS }
        className={ `${ path === Urls.INGREDIENTS ? 'active' : '' }` }
      >
        { process.env.REACT_APP_INGREDIENTS }
      </Nav.Link>
    </LinkContainer>
    <LinkContainer to={ Urls.PREMENUS }>
      <Nav.Link
        href={ Urls.PREMENUS }
        className={ `${ path === Urls.PREMENUS ? 'active' : '' }` }
      >
        { process.env.REACT_APP_PLANS }
      </Nav.Link>
    </LinkContainer>
  </>
}
