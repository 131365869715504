import * as React from 'react';
import { Button } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';
import { Location, matchPath, useLocation } from 'react-router-dom';
import * as _ from 'underscore';

import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';
import { HeaderCart } from '@stack/frontend-core/ui/header-cart';

import Account from 'cls/Account';
import { ActionTypes } from 'cls/redux/actions';
import { StoreDispatch, StoreState } from 'cls/store';
import { HeaderLogo } from 'page/header/logo';
import { MainLinks } from 'page/header/main-links';
import NameDropdown from 'page/header/name';
import HeaderNotifications from 'page/header/notifications';
import AddMultiButton from 'ui/add-multi-button';
import PickProfileModal from 'ui/profiles-modal';
import Search from 'ui/search';
import { SidebarToggler } from 'ui/sidebar/toggler';

import 'page/main/style.css';

interface Props {
  location: Location,
}

interface ReduxStateProps {
  account?: Account;
  profile?: string;
}

interface ReduxDispatchProps {
  getAccount: () => void;
  getUser: () => void;
  getCellar: () => void;
  getBasicData: () => void;
  recache: () => void;
  toggleSidebar: () => void;
  listMenuItemsUser: (_: string) => void;
  listFlags: () => void;
}

interface State {
  showSearchNav: boolean,
}

const urlsWithSidebar = [
  process.env.REACT_APP_URL_INGREDIENTS || '',
  process.env.REACT_APP_URL_RECIPES || '',
  process.env.REACT_APP_URL_PREMENUS || '',
  process.env.REACT_APP_URL_MENU || '',
  process.env.REACT_APP_URL_SHOPPING_LISTS || '',
  process.env.REACT_APP_URL_CELLAR || '',
  process.env.REACT_APP_URL_RECIPES_MY || '',
  process.env.REACT_APP_URL_PREMENUS_MY || '',
  process.env.REACT_APP_URL_FRIENDS || '',
  process.env.REACT_APP_URL_FRIEND_INVITATIONS || '',
  process.env.REACT_APP_URL_MY_GENERAL || '',
  process.env.REACT_APP_URL_MY_SUBSCRIPTIONS || '',
  process.env.REACT_APP_URL_MY_ORDERS || '',
  process.env.REACT_APP_URL_MY_ACCOUNT || '',
  process.env.REACT_APP_URL_PROFILES || '',
  process.env.REACT_APP_URL_RECIPE_EDIT || '',
  process.env.REACT_APP_URL_PREMENU_EDIT || '',
  process.env.REACT_APP_URL_MY_ORDER || '',
];

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {

  constructor(props: Props & ReduxDispatchProps & ReduxStateProps) {
    super(props);
    this.state = { showSearchNav: false };
  }

  componentDidMount() {
    const {
      getAccount, getBasicData, getUser,
      getCellar, listMenuItemsUser,
      profile, listFlags,
      recache,
    } = this.props;
    getAccount();
    getBasicData();
    getUser();
    getCellar();
    if (profile !== undefined) {
      listMenuItemsUser(profile);
    }
    listFlags();
    _.delay(() => recache(), 2 * 1000);
  }

  componentDidUpdate(prevProps: Props & ReduxStateProps & ReduxDispatchProps) {
    const { profile, listMenuItemsUser } = this.props;
    if (profile !== undefined && prevProps.profile !== profile) {
      listMenuItemsUser(profile);
    }
  }

  isWithSidebar() {
    const { location } = this.props;
    const path = decodeURI(location.pathname);
    const mathingUrls = urlsWithSidebar.filter(pattern => matchPath(pattern, path));
    return mathingUrls.length > 0;
  }

  render() {
    const { showSearchNav } = this.state;
    const { account } = this.props;

    const searchIcon =
      <Button
        size="lg"
        className="btn-icon fs-xl border-0 rounded-circle"
        aria-label="Toggle search bar"
        onClick={ () => this.setState({ showSearchNav: !showSearchNav }) }
        variant="outline-secondary"
      >
        <i className="czi-search"></i>
      </Button>;

    const togglerIcon = <SidebarToggler/>;

    const headerLg = <header className="bg-light box-shadow-sm fixed-top d-none d-lg-block">
      <Navbar bg="light" expand="lg" sticky="top">
        <div className="container-fluid">
          <HeaderLogo/>
          <div className="navbar-nav ms-4">
            <MainLinks/>
          </div>
          <AddMultiButton/>
          <Search className="mx-lg-4"/>
          <div className="navbar-toolbar flex-shrink-0">
            <HeaderNotifications/>
          </div>
          <div className="navbar-toolbar flex-shrink-0">
            <NameDropdown/>
          </div>
          <HeaderCart/>
        </div>
      </Navbar>
    </header>

    const headerMd = <header className="bg-light box-shadow-sm fixed-top d-block d-lg-none">
      <Navbar bg="light" expand="lg" sticky="top">
        <div className="container-fluid px-0">
          <HeaderLogo/>
          <div className="d-flex flex-row">
            { searchIcon }
            { this.isWithSidebar() && togglerIcon }
          </div>
          <Navbar.Collapse id="navbar-collapse" className={ `me-auto order-lg-2 ${ showSearchNav ? "show" : "" }` }>
            <Search className="mx-lg-4"/>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>;

    return (
      <>
        { account !== undefined &&
          <PickProfileModal/>
        }
        { headerLg }
        { headerMd }
      </>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
    account: state.account.account === null ? undefined : state.account.account,
    profile: state.account.profile === null ? undefined : state.account.profile,
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    listMenuItemsUser: (profile: string) => dispatch({ type: ActionTypes.ListMenuItemsUser, profile }),
    getAccount: () => dispatch({ type: ActionTypes.GetAccount }),
    getUser: () => dispatch({ type: CoreActionTypes.GetUser }),
    getCellar: () => dispatch({ type: ActionTypes.ListCellarItems }),
    getBasicData: () => dispatch({ type: ActionTypes.GetAllBasic }),
    recache: () => dispatch({ type: CoreActionTypes.RevalidateCache }),
    toggleSidebar: () => dispatch({ type: ActionTypes.ToggleSidebar }),
    listFlags: () => dispatch({ type: CoreActionTypes.ListFlags }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const Header = () => (
  <ConnectedScene location={ useLocation() }/>
);

export default Header;
