import * as _ from 'underscore';

import RawQuantity from 'cls/RawQuantity';
import { Measure as MeasureEnum } from 'thriftgen';


const m: { [key: string]: number | number[] } = {
  'portion': 0,
  'moisture': 1,
  'ash': 2,
  'protein': 3,
  'fat': 4,
  'carbohydrate': 5,
  'energyKcal': 7,

  'fibre': 9,
  'starch': 10,
  'glucose': 11,
  'fructose': 12,
  'maltose': 13,
  'galactose': 14,
  'lactose': 15,
  'sucrose': 16,
  'sorbitol': 17,
  'sugars': 21,

  'minCa': 22,
  'minFe': 23,
  'minMg': 24,
  'minP': 25,
  'minK': 26,
  'minNa': 27,
  'minCu': 29,
  'minMn': 30,
  'minSe': 31,

  'vitB1': 44,
  'vitB2': 43,
  'vitB3': 40,
  'vitB4': 49,
  'vitB5': 42,
  'vitB6': 45,
  'vitB7': 48,
  'vitB9': 36,
  'vitB12': 46,
  'vitA': [ 33, 32, 34, 35 ],
  'vitD': 52,
  'vitE': [ 57, 58, 59, 60 ],
  'vitK': 55,
  'vitC': 51,

  'fattyAcidsSaturatedTotal': 61,
  'fattyAcidsMonounsaturatedTotal': 76,
  'fattyAcidsPolyunsaturatedTotal': 95,
  'fattyAcidsTrans': 125,
  'cholesterol': 126,

  'carbohydrateNoFibre': 0,
}


type Portion = {
  medium?: number;
  slice?: number;
  sprig?: number;
  bunch?: number;
  g?: number;
  stalk?: number;
  ml?: number;
  handful?: number;
  count?: { n: number, m: number };
}

function normalize(q: { n: number, m: number }, p: Portion): { n: number, m: number } {
  let normalizationRules: { [key: number]: [ number, number ] } = {}
  normalizationRules[MeasureEnum.TABLESPOON] = [ 15, MeasureEnum.ML ];
  normalizationRules[MeasureEnum.CUP] = [ 250, MeasureEnum.ML ];
  normalizationRules[MeasureEnum.L] = [ 1000, MeasureEnum.ML ];
  normalizationRules[MeasureEnum.PINCH] = [ 0.5, MeasureEnum.ML ];
  normalizationRules[MeasureEnum.TEASPOON] = [ 5, MeasureEnum.ML ];
  normalizationRules[MeasureEnum.KG] = [ 1000, MeasureEnum.G ];

  if (p.slice !== undefined) {
    normalizationRules[MeasureEnum.SLICE] = [ p.slice, MeasureEnum.G ];
  }
  if (p.sprig !== undefined) {
    normalizationRules[MeasureEnum.SPRIG] = [ p.sprig, MeasureEnum.G ];
  }
  if (p.bunch !== undefined) {
    normalizationRules[MeasureEnum.BUNCH] = [ p.bunch, MeasureEnum.G ];
  }
  if (p.stalk !== undefined) {
    normalizationRules[MeasureEnum.STALK] = [ p.stalk, MeasureEnum.G ];
  }
  if (p.medium !== undefined) {
    normalizationRules[MeasureEnum.G] = [ 1 / p.medium, MeasureEnum.COUNT ];
  }
  if (p.ml !== undefined) {
    normalizationRules[MeasureEnum.ML] = [ p.ml / 100, MeasureEnum.G ];
  }
  if (p.handful !== undefined) {
    normalizationRules[MeasureEnum.HANDFUL] = [ p.handful, MeasureEnum.G ];
  }
  if (p.count !== undefined) {
    const qn = normalize(p.count, {});
    normalizationRules[MeasureEnum.COUNT] = [ qn.n, qn.m ];
    normalizationRules[MeasureEnum.PORTION] = [ qn.n, qn.m ];
  }

  const rule = normalizationRules[q.m];
  if (rule === undefined) {
    return q;
  }
  return normalize({ n: q.n * rule[0], m: rule[1] }, p);
}

function multiply(x: number, xs: number[]): number[] {
  return xs.map((y) => x * y);
}

function add(ax: number[] | undefined, bx: number[] | undefined): number[] | undefined {
  if (ax === undefined) { return bx; }
  if (bx === undefined) { return ax; }
  return _.zip(ax, bx).map(([ a, b ]) => a + b);
}

export class N {
  mx: { [key: string]: number[] };
  px: { [key: string]: Portion };

  constructor(mx: { [key: string]: number[] }, px: { [key: string]: Portion }) {
    this.mx = mx;
    this.px = px;
  }

  getIngredientVals(name: string): { [key: string]: number } {
    const i = m[name];
    const result: { [key: string]: number } = {};

    if (i === undefined) {
      switch (name) {
        case 'carbohydratePercentage': {
          const carbohydrate = m['carbohydrate'] as number;
          const portion = m['portion'] as number;
          _.keys(this.mx).forEach((k) => {
            result[k] = this.mx[k][carbohydrate] / this.mx[k][portion] / 10;
          });
          return result;
        }
        case 'proteinPercentage': {
          const protein = m['protein'] as number;
          const portion = m['portion'] as number;
          _.keys(this.mx).forEach((k) => {
            result[k] = this.mx[k][protein] / this.mx[k][portion] / 10;
          });
          return result;
        }
        case 'fatPercentage': {
          const fat = m['fat'] as number;
          const portion = m['portion'] as number;
          _.keys(this.mx).forEach((k) => {
            result[k] = this.mx[k][fat] / this.mx[k][portion] / 10;
          });
          return result;
        }
      }
      return result;
    }

    _.keys(this.mx).forEach((k) => {
      if (typeof i === 'number') {
        result[k] = this.mx[k][i];
        return;
      }
      const ne = this.mx[k];
      result[k] = _.reduce(
        i.map((x: number) => ne[x] || 0),
        (x, y) => x + y,
        0,
      );
    });
    return result;
  }

  getForIngredient(id: string, q: { n: number, m: number }): number[] | undefined {
    let n = this.mx[id];
    if (n === undefined) { return; }
    const rate = N.getRate(q, this.px[id] || {}, id);
    if (rate === undefined) { return undefined; }
    return multiply(rate, n);
  }

  getForIngredients(xs: [ string, { n: number, m: number } ][], mult: number = 1): number[] | undefined {
    if (xs.length === 0) { return undefined; }
    const result = _.reduce<[ string, { n: number, m: number } ][], number[] | undefined>(
      xs,
      (s, x) => {
        const r = this.getForIngredient(x[0], x[1]);
        if (r === undefined) { return s; }
        return add(s, r);
      },
      undefined
    );
    if (result === undefined) { return undefined;}
    return multiply(mult, result);
  }

  getG(id: string, q: { n: number, m: number }): number | undefined {
    let n = this.mx[id];
    if (n === undefined) { return undefined; }
    const rate = N.getRate(q, this.px[id] || {}, id);
    if (rate === undefined) { return undefined; }
    return rate * n[0];
  }

  getGs(xs: [ string, { n: number, m: number } ][], mult: number = 1): [ string, string ][] {
    return xs
      .map<[ string, number | undefined ]>(([ id, q ]) => [ id, this.getG(id, q) ])
      .filter(([ _, g ]) => g !== undefined)
      .map(([ id, g ]) => [ id, (g || 0) * mult ] as [ string, number ])
      .sort((a, b) => b[1] - a[1])
      .map(([ a, b ]) => [ a, b.toFixed(2).replace(/\.0+$/, "") ]);
  }

  sum(xs: [ string, { n: number, m: number } ][]): [ string, { n: number, m: number } ][] {
    const i: { [key: string]: { n: number, m: number }; } = {};

    xs.forEach(([ id, q ]) => {
      if (i[id] !== undefined) {
        const q1 = new RawQuantity(q.n, q.m, this.px[id]);
        const q2 = new RawQuantity(i[id].n, i[id].m, this.px[id]);
        const q12 = RawQuantity.add(q1, q2);
        if (q12 !== undefined) {
          i[id] = { n: q12.n, m: q12.m };
        } else {
          console.warn('Cannot add', id);
        }
      } else {
        i[id] = { n: q.n, m: q.m };
      }
    });

    return _.keys(i).map((k) => [ k, i[k] ]);
  }

  static getRate(qa: { n: number, m: number }, p: Portion, ingredientId: string) {
    const q = normalize(qa, p);
    if (q.m === MeasureEnum.COUNT && p.medium !== undefined) {
      return p.medium * q.n / 100;
    } else if (q.m === MeasureEnum.G) {
      return q.n / 100;
    } else if (q.m === MeasureEnum.ML && p.ml !== undefined) {
      return p.ml * q.n / 10000;
    } else if (q.m === MeasureEnum.SLICE && p.slice !== undefined) {
      return p.slice * q.n / 100;
    } else if (q.m === MeasureEnum.SPRIG && p.sprig !== undefined) {
      return p.sprig * q.n / 100;
    } else if (q.m === MeasureEnum.BUNCH && p.bunch !== undefined) {
      return p.bunch * q.n / 100;
    } else if (q.m === MeasureEnum.STALK && p.stalk !== undefined) {
      return p.stalk * q.n / 100;
    } else if (q.m === MeasureEnum.HANDFUL && p.handful !== undefined) {
      return p.handful * q.n / 100;
    } else {
      console.warn("getForIngredient measure not known", ingredientId, q, p);
    }
  }
}

