import { Col, Container, Row } from 'react-bootstrap';

import { Header } from 'content/landing/Header';
import FooterScene from 'page/footer';


export const ValueProposition = (props: { title: string, description: string, img: string, i: number }) => {
  const order1 = props.i % 2 === 0 ? 'order-lg-1' : 'order-lg-2';
  const order2 = props.i % 2 === 0 ? 'order-lg-2' : 'order-lg-1';

  return <Row>
    <Col
      xs={ 12 } lg={ 6 }
      className={ `bg-position-center bg-size-cover bg-secondary ${ order1 }` }
      style={ { "minHeight": "20rem", "backgroundImage": `url(${ props.img })` } }
    ></Col>
    <Col xs={ 12 } lg={ 6 } className={ `px-3 px-lg-5 py-5 ${ order2 }` }>
      <div className="m-3" style={ { "maxWidth": "35rem" } }>
        <h2 className="h3 pb-3">{ props.title }</h2>
        <p className="fs-7 pb-3 text-muted">
          { props.description }
        </p>
      </div>
    </Col>
  </Row>;
}

const values = Array(7).fill(1).map((_, i) => ({
  title: process.env['REACT_APP_LANDING_ABOUT_T' + (i + 1)] || "",
  description: process.env['REACT_APP_LANDING_ABOUT_D' + (i + 1)] || "",
  img: `https://brokli.pl/media/img/landing/p${ i + 1 }.png`
}));

export const LandingAboutUsScene = () => {
  return (
    <>
      <Header/>
      <Container fluid={ true }>
        <div className="d-flex flex-column align-items-md-center justify-content-md-center under-nav mb-5">
          <h1 className="display-1 mt-6">{ process.env.REACT_APP_ABOUT_US }</h1>
          <p className="lead">{ process.env.REACT_APP_ABOUT_US_LEAD }</p>
        </div>
      </Container>
      <section>
        { values.map((value, i) =>
          <ValueProposition key={ value.title } i={ i } { ...value } />
        ) }
      </section>
      <FooterScene/>
    </>
  )
};