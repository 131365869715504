// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { LoadingDropdownItem } from '@stack/frontend-core/ui';

import { ActionTypes, CreateShoppingListAction } from 'cls/redux/actions';
import { StoreDispatch, StoreState } from 'cls/store';
import Urls from 'cls/Urls';
import FeatureOn from 'ui/feature-on';


interface Props {}

interface ReduxStateProps {}

interface ReduxDispatchProps {
  createShoppingList: () => void;
}

class Scene extends React.Component<Props & ReduxStateProps & ReduxDispatchProps> {

  constructor(props: Props & ReduxStateProps & ReduxDispatchProps) {
    super(props);
    this.addShoppingList = this.addShoppingList.bind(this);
  }

  addShoppingList() {
    const { createShoppingList } = this.props;
    createShoppingList();
  }

  render() {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="secondary" size="sm" aria-label={ process.env.REACT_APP_CREATE }
        >
          <i className="czi-add me-1"/> { process.env.REACT_APP_CREATE }
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <LoadingDropdownItem
            action="create-shopping-list"
            onClick={ this.addShoppingList }
          >
            { process.env.REACT_APP_SHOPPING || '' }
          </LoadingDropdownItem>
          <LinkContainer to={ Urls.CELLAR_ADD }>
            <Dropdown.Item href={ Urls.CELLAR_ADD }>{ process.env.REACT_APP_CELLAR }</Dropdown.Item>
          </LinkContainer>
          <LinkContainer to={ Urls.RECIPE_NEW }>
            <Dropdown.Item href={ Urls.RECIPE_NEW }>{ process.env.REACT_APP_RECIPE }</Dropdown.Item>
          </LinkContainer>
          <LinkContainer to={ Urls.PREMENU_NEW }>
            <Dropdown.Item href={ Urls.PREMENU_NEW }>{ process.env.REACT_APP_PLAN }</Dropdown.Item>
          </LinkContainer>
          <FeatureOn name="use_party">
            <LinkContainer to={ Urls.PARTY_NEW }>
              <Dropdown.Item href={ Urls.PARTY_NEW }>{ process.env.REACT_APP_PARTY }</Dropdown.Item>
            </LinkContainer>
          </FeatureOn>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({});
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    createShoppingList: () => dispatch({
      type: ActionTypes.CreateShoppingList,
      redirect: (action: CreateShoppingListAction, result: string) => Urls.SHOPPING_LIST_ID(result),
    }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const AddMultiButton = () => (
  <ConnectedScene/>
);

export default AddMultiButton;