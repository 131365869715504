import * as _ from 'underscore';

import Ingredient from 'cls/Ingredient';
import IngredientsList from 'cls/IngredientsList';
import Measure from 'cls/Measure';
import Portion from 'cls/Portion';
import Quantity from 'cls/Quantity';
import Recipe from 'cls/Recipe';
import Utils from 'cls/Utils';
import X from 'cls/X';
import { Measure as MeasureEnum } from 'thriftgen';
import { RecipeIngredient as ThriftRecipeIngredient } from 'thriftgen/RecipeIngredient'
import { PickQuantityItemType } from 'ui/pick-quantity';

export default class RecipeIngredient extends X {
  ingredientId: string | undefined;
  recipeId: string | undefined;
  comment: string;
  nutrition: number;
  ingredient?: Ingredient;
  recipe?: Recipe;

  constructor(x: ThriftRecipeIngredient, is: Ingredient[]) {
    super(x.q);
    this.ingredientId = x.ingredientId || undefined;
    this.recipeId = x.recipeId || undefined;
    this.comment = x.comment || "";
    this.ingredient = is.find((y) => y.id === x.ingredientId);
    this.nutrition = x.nutrition === undefined ? 1 : x.nutrition;
    if (this.ingredient !== undefined) {
      this.setPortion(this.ingredient.portions);
    }
  }

  static init = (xs: ThriftRecipeIngredient[], is: Ingredient[]) =>
    xs.map((x) => new RecipeIngredient(x, is));

  static shoppingList(xs: RecipeIngredient[], nutrition: boolean) {
    const allIngredients: RecipeIngredient[] = xs.flatMap(
      (x) => x.shoppingList(nutrition)
    )
    return RecipeIngredient.sum(allIngredients);
  }

  thrift(): ThriftRecipeIngredient {
    return new ThriftRecipeIngredient({
      comment: this.comment,
      ingredientId: this.ingredientId,
      nutrition: this.nutrition,
      recipeId: this.recipeId,
      q: this.q,
    });
  }

  copy(): RecipeIngredient {
    const rc = new RecipeIngredient(new ThriftRecipeIngredient({
      ingredientId: this.ingredientId,
      recipeId: this.recipeId,
      comment: this.comment,
      nutrition: this.nutrition,
      q: this.q,
    }), []);
    rc.ingredient = this.ingredient;
    rc.recipe = this.recipe;
    rc.setPortion(this.portions);
    return rc;
  }

  setRecipes(xs: Recipe[]) {
    if (this.recipeId === undefined) {
      return;
    }
    this.recipe = xs.find((y) => y.id === this.recipeId);
    if (this.recipe === undefined || this.recipe.count === undefined) { return; }
    this.setPortion(this.recipe.portions);
    if (this.q.m === MeasureEnum.COUNT) {
      const q = Quantity.init4(this.recipe.count, this.recipe.portions);
      q.multiply(q.n);
      this.setQuantity(q);
    }
  }

  setIngredients(xs: Ingredient[]) {
    if (this.ingredientId === undefined) {
      return;
    }
    this.ingredient = xs.find((y) => y.id === this.ingredientId);
  }

  get item(): PickQuantityItemType | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient;
    }
    if (this.recipe !== undefined) {
      return this.recipe;
    }
    return undefined;
  }

  get id(): string {
    return this.ingredientId || this.recipeId || "";
  }

  get numsCountG(): [ number, number ] {
    const q = this.quantity;

    const count = Measure.all()[MeasureEnum.COUNT];
    const g = Measure.all()[MeasureEnum.G];

    const inCount = q.in(count.q);
    const inG = q.in(g.q);

    if (inCount === undefined) {
      console.warn("cannot convert to count, ingredient: %s, recipe %s, q: %o", this.ingredientId, this.recipeId, q);
    }
    if (inCount === undefined) {
      console.warn("cannot convert to g, ingredient: %s, recipe %s, q: %o", this.ingredientId, this.recipeId, q);
    }

    return [
      inCount?.n || 0,
      inG?.n || 0,
    ];
  }

  get prettyPrintNum(): string {
    const q = this.quantity;
    const measures = [ Measure.all()[MeasureEnum.COUNT], Measure.all()[MeasureEnum.G] ];
    const names: string[] = measures.map((m) => {
      const qInM = q.in(m.q);
      if (qInM === undefined) {
        console.warn('Cannot prettyPrintNum', q, m);
        return '';
      }
      return qInM.m.text(qInM.n);
    });
    return names.join(',');
  }

  get inKg(): number {
    const qInKG = this.quantity.in(Measure.all()[MeasureEnum.KG].q);
    if (qInKG !== undefined) {
      return qInKG.n;
    }
    return 0;
  }

  get prettyPrintSummary(): string {
    let name = "";
    if (this.ingredient !== undefined) {
      name = this.ingredient.name;
    } else if (this.recipe !== undefined) {
      name = this.recipe.name;
    } else {
      console.warn('No data', this);
      return `<i:${ this.ingredientId },r:${ this.recipeId },q:${ this.q }>`;
    }

    if (this.ingredient !== undefined) {
      const names: string[] = [];
      const qInKG = this.quantity.in(Measure.all()[MeasureEnum.KG].q);
      if (qInKG !== undefined) {
        names.push(qInKG.m.text(qInKG.n));
      }
      const qInCount = this.quantity.in(Measure.all()[MeasureEnum.COUNT].q);
      if (qInCount !== undefined) {
        names.push(qInCount.m.text(qInCount.n));
      }
      if (names.length === 0) {
        names.push(this.quantity.m.text(this.quantity.n));

      }
      names.push(name.toLowerCase());
      return `${ names.join(', ') }`;
    }

    return "";
  }

  get prettyPrint(): string {
    let name = "";
    let nameGenitive = "";
    let pluralName = "";
    let pluralNameGenitive = "";

    if (this.ingredient !== undefined) {
      name = this.ingredient.name;
      nameGenitive = this.ingredient.nameGenitive;
      pluralName = this.ingredient.pluralName;
      pluralNameGenitive = this.ingredient.pluralNameGenitive;
    } else if (this.recipe !== undefined) {
      name = this.recipe.name;
      nameGenitive = this.recipe.nameGenitive;
      pluralName = this.recipe.pluralName;
      pluralNameGenitive = this.recipe.pluralNameGenitive;
    } else {
      console.warn('No data', this);
      return `<i:${ this.ingredientId },r:${ this.recipeId },q:${ this.q }>`;
    }

    let comment: string = "";
    if (this.comment !== "") {
      comment = ` (${ this.comment })`;
    }

    const q = this.quantity;
    const simple: MeasureEnum[] = [
      MeasureEnum.COUNT,
    ];
    if (q.m.q === MeasureEnum.COUNT && this.recipe !== undefined) {
      q.m = Measure.all()[MeasureEnum.PORTION];
      return `${ q.m.text(q.n) } ${ nameGenitive.toLowerCase() }${ comment }`;
    }

    if (this.ingredient !== undefined && this.ingredient.printM !== undefined) {
      const p = this.ingredient.printM;
      const names: string[] = p.map((m) => {
        const qInM = q.in(m.q);
        if (qInM === undefined) { return ''; }
        if (simple.includes(m.q)) {
          const finalName: string = Utils.polishPlural(
            name, nameGenitive, pluralName, pluralNameGenitive, qInM.n
          );

          return `${ qInM.m.text(qInM.n) } ${ finalName.toLowerCase() }`;
        }

        return `${ qInM.m.text(qInM.n) } ${ nameGenitive.toLowerCase() }`;
      });
      return `${ names.join(', ') }${ comment }`;
    }

    if (simple.includes(q.m.q)) {
      const finalName: string = Utils.polishPlural(
        name, nameGenitive, pluralName, pluralNameGenitive, q.n
      );

      return `${ q.m.text(q.n) } ${ finalName.toLowerCase() }${ comment }`;
    }

    return `${ q.m.text(q.n) } ${ nameGenitive.toLowerCase() }${ comment }`;
  }

  get tags(): string[] {
    if (this.ingredient !== undefined) {
      return this.ingredient.tags;
    }
    if (this.recipe !== undefined) {
      return this.recipe.tags;
    }
    return [];
  }

  get measure(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.measure;
    }
    if (this.recipe !== undefined) {
      return this.recipe.measure;
    }
    return "";
  }

  get serving(): number | undefined {
    if (this.recipe !== undefined) {
      return this.recipe.serving;
    }
    return undefined;
  }

  get portions(): Portion {
    if (this.ingredient !== undefined) {
      return this.ingredient.portions;
    }
    if (this.recipe !== undefined) {
      return this.recipe.portions;
    }
    return new Portion(undefined);
  }

  multiply(x: number): RecipeIngredient {
    const is: Ingredient[] = [];
    const ir: Recipe[] = [];
    if (this.ingredient !== undefined) {
      is.push(this.ingredient);
    }
    if (this.recipe !== undefined) {
      ir.push(this.recipe);
    }
    const q = this.quantity.copy();
    q.multiply(x)

    const result = new RecipeIngredient(new ThriftRecipeIngredient({
        ingredientId: this.ingredientId,
        recipeId: this.recipeId,
        comment: this.comment,
        nutrition: this.nutrition,
        q: q.q,
      }), is
    );
    result.setRecipes(ir);
    return result;
  }

  static sum(xs: RecipeIngredient[]): RecipeIngredient[] {
    const r: { [key: string]: RecipeIngredient; } = {};
    const i: { [key: string]: RecipeIngredient; } = {};
    const result: RecipeIngredient[] = [];

    xs.forEach((x) => {
      if (x.recipeId !== undefined) {
        if (r[x.recipeId] !== undefined) {
          const q1 = x.quantity
          const q2 = r[x.recipeId].quantity;
          const q12 = Quantity.add(q1, q2);
          if (q12 !== undefined) {
            r[x.recipeId].setQuantity(q12);
          } else {
            console.warn('Cannot add', x.recipeId);
          }
        } else {
          r[x.recipeId] = x.copy();
        }
      }
      if (x.ingredientId !== undefined) {
        if (i[x.ingredientId] !== undefined) {
          const q1 = x.quantity;
          const q2 = i[x.ingredientId].quantity;
          const q12 = Quantity.add(q1, q2);
          if (q12 !== undefined) {
            i[x.ingredientId].setQuantity(q12);
          } else {
            console.warn('Cannot add', x.ingredientId);
          }
        } else {
          i[x.ingredientId] = x.copy();
        }
      }
    });
    _.map(i, (x) => result.push(x));
    _.map(r, (x) => result.push(x));

    return result;
  }

  static subtract(xs1: RecipeIngredient[], xs2: RecipeIngredient[]): RecipeIngredient[] {
    const xss1 = RecipeIngredient.sum(xs1);
    const xss2 = RecipeIngredient.sum(xs2);
    const r: { [key: string]: RecipeIngredient; } = {};
    const i: { [key: string]: RecipeIngredient; } = {};
    const result: RecipeIngredient[] = [];
    xss1.forEach((x) => {
      if (x.recipeId !== undefined) {
        r[x.recipeId] = x.copy();
      }
      if (x.ingredientId !== undefined) {
        i[x.ingredientId] = x.copy();
      }
    });

    xss2.forEach((x) => {
      if (x.recipeId !== undefined) {
        if (r[x.recipeId] !== undefined) {
          const q1 = x.quantity;
          const q2 = r[x.recipeId].quantity;
          const q12 = Quantity.subtract(q2, q1);
          if (q12 !== undefined && q12.n === 0) {
            delete r[x.recipeId];
          } else if (q12 !== undefined) {
            r[x.recipeId].setQuantity(q12);
          } else {
            console.warn('Cannot subtract', x.recipeId);
          }
        } else {
          r[x.recipeId] = x.copy().multiply(-1);
        }
      }
      if (x.ingredientId !== undefined) {
        if (i[x.ingredientId] !== undefined) {
          const q1 = x.quantity;
          const q2 = i[x.ingredientId].quantity;
          const q12 = Quantity.subtract(q2, q1);
          if (q12 !== undefined && q12.n === 0) {
            delete i[x.ingredientId];
          } else if (q12 !== undefined) {
            i[x.ingredientId].setQuantity(q12);
          } else {
            console.warn('Cannot subtract', x.ingredientId);
          }
        } else {
          i[x.ingredientId] = x.copy().multiply(-1);
        }
      }
    });
    _.map(i, (x) => result.push(x));
    _.map(r, (x) => result.push(x));

    return result;
  }

  shoppingList(nutrition: boolean): RecipeIngredient[] {
    let mult = 1;
    if (nutrition) {
      mult = this.nutrition;
    }
    if (this.ingredient !== undefined) {
      return [ this.multiply(mult) ];
    }
    if (this.recipe !== undefined) {
      const q = this.quantity.normalize();
      let num = 1;
      if (q.m.q === MeasureEnum.COUNT) {
        num = q.n;
      } else if (q.m.q === MeasureEnum.PORTION) {
        num = q.n / this.recipe.serving;
      } else if (q.m.q === MeasureEnum.ML) {
        const cq = Quantity.init4(this.recipe.count || Quantity.defaultOne().q, undefined).normalize();
        if (cq.m.q === MeasureEnum.ML) {
          num = q.n / cq.n;
        } else {
          console.warn("No match for recipe multiplier for ", this.recipeId);
        }
      } else if (q.m.q === MeasureEnum.G) {
        const cq = Quantity.init4(this.recipe.count || Quantity.defaultOne().q, undefined).normalize();
        if (cq.m.q === MeasureEnum.G) {
          num = q.n / cq.n;
        } else {
          console.warn("No match for recipe multiplier for ", this.recipeId);
        }
      } else {
        console.warn("No match for recipe multiplier for ", this.recipeId);
      }
      return IngredientsList.recipe(this.recipe, nutrition).map((x) => x.multiply(num * mult));
    }

    return [];
  }

  get name(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.name;
    }
    if (this.recipe !== undefined) {
      return this.recipe.name;
    }
    return '';
  }

  get category(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.category || "";
    }
    if (this.recipe !== undefined) {
      return this.recipe.category || "";
    }
    return '';
  }
}
