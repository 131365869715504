export const NavMedia = (props: {
  icons: [ string, string, string, string ],
  title: string,
  textEnvVarPrefix: string,
}) => {
  return <>
    <h3 className="h6 fw-normal text-center mt-2">
      { props.title }
    </h3>
    <div className="row row-cols-1 row-cols-lg-2 g-2 p-2">
      { [ 1, 2, 3, 4 ].map((x) =>
        <div key={ x } className={ `d-flex align-items-center ${ x === 1 && 'active' }` }>
          <div className="media-tab-media me-3 d-flex align-items-center justify-content-center">
            <i className={ `${ props.icons[x - 1] } fs-5` }/>
          </div>
          <div className={ `${ x === 1 && 'text-primary' }` }>
            <div className={ `fs-8 mb-1 ${ x === 1 ? 'text-primary' : 'text-muted' }` }>
              { process.env[`REACT_APP_STEP${ x }`] }
            </div>
            <h6 className={ `media-tab-title text-nowrap mb-0 ${ x === 1 && 'text-primary' }` }>
              { process.env[`${ props.textEnvVarPrefix }${ x }`] }
            </h6>
          </div>
        </div>
      ) }
    </div>
  </>;
}

export const HowToStart = () => {
  return <NavMedia
    icons={ [ "czi-pot", "czi-rocket", "czi-delivery", "czi-heart" ] }
    title={ process.env.REACT_APP_HOW_TO_START || '' }
    textEnvVarPrefix="REACT_APP_HOW_TO_START_S"
  />;
}

export const HowItWorks = () => {
  return <NavMedia
    icons={ [
      "czi-rocket",
      "czi-delivery",
      "czi-pot",
      "czi-heart",
    ] }
    title={ process.env.REACT_APP_HOW_TO_START || '' }
    textEnvVarPrefix="REACT_APP_HOW_IT_WORKS_S"
  />;
}