import { LinkContainer } from 'react-router-bootstrap';

import Urls from 'cls/Urls';


export const Banner = () => {
  return (
    <section
      className="d-flex justify-content-center page-title bg-darker bg-size-cover bg-position-center text-white py-5 px-3 p-md-6 mb-5"
    >
      <div className="d-flex flex-column align-items-center banner">
        <h1 className="text-center text-white">{ process.env.REACT_APP_LANDING_HEADER }</h1>
        <p className="text-center mt-2 mb-4" style={ { "fontSize": "1.2rem" } }>
          { process.env.REACT_APP_LANDING_SUBHEADER }
        </p>
        <LinkContainer to={ Urls.REGISTER }>
          <a href={ Urls.REGISTER } className="btn btn-primary btn-lg" type="button">
            { process.env.REACT_APP_LANDING_BUTTON }
          </a>
        </LinkContainer>
      </div>
    </section>
  );
}