// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { LoadingBox } from '@stack/frontend-core';
import { AreYouSureModal, Toasts } from '@stack/frontend-core/ui';

import { StoreState } from 'cls/store';
import Urls from 'cls/Urls';
import Utils from 'cls/Utils';
import { LandingAboutUsScene } from 'content/landing/scenes/AboutUs';
import { LandingFeaturesScene } from 'content/landing/scenes/Features';
import { LandingHomepageScene } from 'content/landing/scenes/Homepage';
import { LandingPricingScene } from 'content/landing/scenes/Pricing';
import DbSelectScene from 'content/other/db-select';
import { CheckEmailPage, ErrorPage } from 'content/other/info-page';
import FooterScene from 'page/footer';
import FooterMobileScene from 'page/footer-mobile';
import Header from 'page/header';

import 'page/main/style.css';


interface Props {}

interface ReduxStateProps {}

interface ReduxDispatchProps {}

interface State {}

const EmailVerifyScene = React.lazy(() => import('content/account/email-verify'));
const LoginScene = React.lazy(() => import('content/account/login'));
const RegisterScene = React.lazy(() => import('content/account/register'));
const OtherLinksScene = React.lazy(() => import ('content/account/other-links'));
const PasswordReset1Scene = React.lazy(() => import('content/account/password-reset-1'));
const PasswordReset2Scene = React.lazy(() => import('content/account/password-reset-2'));

const ProfileScene = React.lazy(() => import('content/account/detail-profile'));
const ListIngredientsScene = React.lazy(() => import('content/ingredient/list'));
const ListPremenusScene = React.lazy(() => import('content/premenu/list'));
const ListRecipesScene = React.lazy(() => import('content/recipe/list'));
const NutritionIngredientsSortScene = React.lazy(() => import('content/summary/nutrition-ingredients-sort'));

const CheckoutScene = React.lazy(() => import('content/ecommerce/checkout-cart'));
const OrderConfirmationScene = React.lazy(() => import('content/ecommerce/order-confirmation'));
const HomepageScene = React.lazy(() => import('content/other/homepage'));

const ManageProfilesScene = React.lazy(() => import('content/account/manage-profiles'));
const MyAccountScene = React.lazy(() => import('content/account/my-account'));
const MyOrderScene = React.lazy(() => import('content/account/my-order'));
const MyOrdersScene = React.lazy(() => import('content/account/my-orders'));
const MySubscriptionsScene = React.lazy(() => import('content/account/my-subscriptions'));
const MyGeneralInfoScene = React.lazy(() => import('content/account/my-general-info'));
const AddCellarItemScene = React.lazy(() => import('content/cellar/add'));
const DetailCellarScene = React.lazy(() => import('content/cellar/detail'));
const AddFriendScene = React.lazy(() => import("content/friend/add-friend"));
const ListFriendInvitationsScene = React.lazy(() => import("content/friend/list-friend-invitations"));
const ListFriendsScene = React.lazy(() => import('content/friend/list-friends'));
const DetailIngredientScene = React.lazy(() => import('content/ingredient/detail'));
const ListMenuScene = React.lazy(() => import('content/menu/list'));
const DetailPartyScene = React.lazy(() => import('content/party/detail'));
const ListPartiesScene = React.lazy(() => import('content/party/list'));
const UpsertPartyScene = React.lazy(() => import('content/party/upsert'));
const DetailPremenuScene = React.lazy(() => import('content/premenu/detail'));
const UpsertPremenuScene = React.lazy(() => import('content/premenu/upsert'));
const DetailRecipeScene = React.lazy(() => import('content/recipe/detail'));
const ListEditableRecipesScene = React.lazy(() => import('content/general/list-editable/recipe'));
const ListEditablePremenusScene = React.lazy(() => import('content/general/list-editable/premenu'));
const UpsertRecipeScene = React.lazy(() => import('content/recipe/upsert'));
const DetailShoppingListScene = React.lazy(() => import('content/shopping-list/detail'));
const ListShoppingListsScene = React.lazy(() => import('content/shopping-list/list'));
const CalendarScene = React.lazy(() => import('content/summary/calendar'));
const NutritionSummaryScene = React.lazy(() => import('content/summary/nutrition-summary'));
const NutritionSummaryIntroScene = React.lazy(() => import('content/summary/nutrition-summary-intro'));
const IngredientsSummaryScene = React.lazy(() => import('content/summary/summary-ingredients'));
const RecipesSummaryScene = React.lazy(() => import('content/summary/summary-recipes'));
const SendFeedbackScene = React.lazy(() => import('content/account/send-feedback'));

const getPrivateRouteComponent = (isAuthenticated: boolean) => {
  return function PrivateRoute({ children }: { children: JSX.Element }) {
    return isAuthenticated ?
      <React.Suspense fallback={ <LoadingBox/> }>{ children }</React.Suspense> :
      <Navigate to={ { pathname: Urls.LOGIN } }/>;
  };
};

const PrivateRouteTrue = getPrivateRouteComponent(true);
const PrivateRouteFalse = getPrivateRouteComponent(false);

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  render() {
    const isAuthenticated = Utils.isLoggedIn();
    const Priv = isAuthenticated ? PrivateRouteTrue : PrivateRouteFalse;

    if (!isAuthenticated) {
      return (
        <>
          <Routes>
            <Route
              path={ Urls.REGISTER }
              element={ <React.Suspense fallback={ <LoadingBox/> }><RegisterScene/></React.Suspense> }
            />
            <Route
              path={ Urls.LOGIN } element={ <React.Suspense fallback={ <LoadingBox/> }><LoginScene/></React.Suspense> }
            />
            <Route
              path={ Urls.PASSWORD_RESET2 }
              element={ <React.Suspense fallback={ <LoadingBox/> }><PasswordReset2Scene/></React.Suspense> }
            />
            <Route
              path={ Urls.PASSWORD_RESET1 }
              element={ <React.Suspense fallback={ <LoadingBox/> }><PasswordReset1Scene/></React.Suspense> }
            />
            <Route
              path={ Urls.EMAIL_VERIFY }
              element={ <React.Suspense fallback={ <LoadingBox/> }><EmailVerifyScene/></React.Suspense> }
            />
            <Route
              path={ Urls.INFO_CHECK_EMAIL }
              element={ <React.Suspense fallback={ <LoadingBox/> }><CheckEmailPage/></React.Suspense> }
            />
            <Route
              path={ Urls.INFO_ERROR }
              element={ <React.Suspense fallback={ <LoadingBox/> }><ErrorPage/></React.Suspense> }
            />
            <Route path={ Urls.FEATURES } element={ <LandingFeaturesScene/> }/>
            <Route path={ Urls.PRICING } element={ <LandingPricingScene/> }/>
            <Route path={ Urls.ABOUT_US } element={ <LandingAboutUsScene/> }/>
            <Route path={ "/" } element={ <LandingHomepageScene/> }/>
          </Routes>
          <AreYouSureModal/>
          <Toasts/>
        </>
      );
    }

    return (
      <>
        <Routes>
          <Route
            path={ Urls.PASSWORD_RESET2 }
            element={ <React.Suspense fallback={ <LoadingBox/> }><PasswordReset2Scene/></React.Suspense> }
          />
          <Route
            path={ Urls.PASSWORD_RESET1 }
            element={ <React.Suspense fallback={ <LoadingBox/> }><PasswordReset1Scene/></React.Suspense> }
          />
          <Route
            path={ Urls.EMAIL_VERIFY }
            element={ <React.Suspense fallback={ <LoadingBox/> }><EmailVerifyScene/></React.Suspense> }
          />
          <Route
            path={ Urls.INFO_CHECK_EMAIL }
            element={ <React.Suspense fallback={ <LoadingBox/> }><CheckEmailPage/></React.Suspense> }
          />
          <Route
            path={ Urls.INFO_ERROR }
            element={ <React.Suspense fallback={ <LoadingBox/> }><ErrorPage/></React.Suspense> }
          />
          <Route path={ Urls.FEATURES } element={ <LandingFeaturesScene/> }/>
          <Route path={ Urls.PRICING } element={ <LandingPricingScene/> }/>
          <Route path={ Urls.ABOUT_US } element={ <LandingAboutUsScene/> }/>
          <Route
            element={ <>
              <Header/>
              <Outlet/>
              <FooterMobileScene/>
            </> }
          >
            <Route path={ Urls.NUTRITION } element={ <Priv><NutritionIngredientsSortScene/></Priv> }/>
            <Route path={ Urls.DB_SELECT } element={ <Priv><DbSelectScene/></Priv> }/>
            <Route path={ Urls.OTHER_LINKS } element={ <Priv><OtherLinksScene/></Priv> }/>
            <Route
              element={
                <>
                  <Outlet/>
                  <FooterScene/>
                </>
              }
            >
              <Route path={ Urls.RECIPES } element={ <Priv><ListRecipesScene/></Priv> }/>
              <Route path={ Urls.INGREDIENTS } element={ <Priv><ListIngredientsScene/></Priv> }/>
              <Route path={ Urls.PREMENUS } element={ <Priv><ListPremenusScene/></Priv> }/>
              <Route path={ Urls.PROFILE } element={ <Priv><ProfileScene/></Priv> }/>
              <Route path={ Urls.RECIPE } element={ <Priv><DetailRecipeScene/></Priv> }/>
              <Route path={ Urls.INGREDIENT } element={ <Priv><DetailIngredientScene/></Priv> }/>
              <Route path={ Urls.PREMENU } element={ <Priv><DetailPremenuScene/></Priv> }/>
              <Route path={ Urls.SHOPPING_LIST } element={ <Priv><DetailShoppingListScene/></Priv> }/>
              <Route path={ Urls.SHOPPING_LISTS } element={ <Priv><ListShoppingListsScene/></Priv> }/>
              <Route path={ Urls.CELLAR_ADD } element={ <Priv><AddCellarItemScene/></Priv> }/>
              <Route path={ Urls.CELLAR } element={ <Priv><DetailCellarScene/></Priv> }/>
              <Route path={ Urls.MENU } element={ <Priv><ListMenuScene/></Priv> }/>
              <Route path={ Urls.PROFILES } element={ <Priv><ManageProfilesScene/></Priv> }/>
              <Route path={ Urls.CALENDAR } element={ <Priv><CalendarScene/></Priv> }/>
              <Route path={ Urls.USER_INGREDIENT_LISTS } element={ <Priv><IngredientsSummaryScene/></Priv> }/>
              <Route path={ Urls.USER_RECIPE_LISTS } element={ <Priv><RecipesSummaryScene/></Priv> }/>
              <Route path={ Urls.USER_NUTRITION_SUMMARY } element={ <Priv><NutritionSummaryScene/></Priv> }/>
              <Route path={ Urls.USER_NUTRITION_SUMMARY_INTRO } element={ <Priv><NutritionSummaryIntroScene/></Priv> }/>
              <Route path={ Urls.RECIPE_EDIT } element={ <Priv><UpsertRecipeScene/></Priv> }/>
              <Route path={ Urls.PREMENU_EDIT } element={ <Priv><UpsertPremenuScene/></Priv> }/>
              <Route path={ Urls.RECIPES_MY } element={ <Priv><ListEditableRecipesScene/></Priv> }/>
              <Route path={ Urls.PREMENUS_MY } element={ <Priv><ListEditablePremenusScene/></Priv> }/>
              <Route path={ Urls.FRIEND_INVITATIONS } element={ <Priv><ListFriendInvitationsScene/></Priv> }/>
              <Route path={ Urls.FRIENDS } element={ <Priv><ListFriendsScene/></Priv> }/>
              <Route path={ Urls.FRIEND_ADD } element={ <Priv><AddFriendScene/></Priv> }/>
              <Route path={ Urls.PARTIES } element={ <Priv><ListPartiesScene/></Priv> }/>
              <Route path={ Urls.PARTY_EDIT } element={ <Priv><UpsertPartyScene/></Priv> }/>
              <Route path={ Urls.PARTY } element={ <Priv><DetailPartyScene/></Priv> }/>
              <Route path={ Urls.RECIPE_NEW } element={ <Priv><UpsertRecipeScene/></Priv> }/>
              <Route path={ Urls.PARTY_NEW } element={ <Priv><UpsertPartyScene/></Priv> }/>
              <Route path={ Urls.PREMENU_NEW } element={ <Priv><UpsertPremenuScene/></Priv> }/>
              <Route path={ Urls.MY_ACCOUNT } element={ <Priv><MyAccountScene/></Priv> }/>
              <Route path={ Urls.MY_SUBSCRIPTIONS } element={ <Priv><MySubscriptionsScene/></Priv> }/>
              <Route path={ Urls.MY_GENERAL } element={ <Priv><MyGeneralInfoScene/></Priv> }/>
              <Route path={ Urls.MY_ORDER } element={ <Priv><MyOrderScene/></Priv> }/>
              <Route path={ Urls.MY_ORDERS } element={ <Priv><MyOrdersScene/></Priv> }/>
              <Route path={ Urls.SEND_FEEDBACK } element={ <Priv><SendFeedbackScene/></Priv> }/>
              <Route path={ Urls.CHECKOUT } element={ <Priv><CheckoutScene/></Priv> }/>
              <Route path={ Urls.ORDER_CONFIRMATION } element={ <Priv><OrderConfirmationScene/></Priv> }/>
              <Route path="/" element={ <Priv><HomepageScene/></Priv> }/>
            </Route>
          </Route>
        </Routes>
        <AreYouSureModal/>
        <Toasts/>
      </>
    );
  }
}

const mapStateToProps = (): ReduxStateProps => {
  return ({});
};

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps,
)(Scene);

const MainScene = () => (
  <ConnectedScene/>
);

export default MainScene;
