import * as React from 'react';

import { Breadcrumbs } from 'ui/breadcrumbs';


const ContentPageHeader = (props: {
  breadcrumbs: { url?: string | undefined, name: string | undefined }[],
  title: string | undefined,
  className?: string | undefined,
}) => {


  return (
    <div className={ `page-title-overlap-lg bg-darker pt-4 ${ props.className || "" }` }>
      <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2 d-none d-lg-block">
          <Breadcrumbs breadcrumbs={ props.breadcrumbs }/>
        </div>
        <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 className="h3 text-light mb-0">{ props.title }</h1>
        </div>
      </div>
    </div>
  );
}
export default ContentPageHeader;
