import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from 'cls/store';
import MainScene from 'page/main';

import 'cartzilla/assets/css/theme.min.css';
import 'assets/fonts/fonts.css';


const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter basename={ process.env.REACT_APP_BASENAME }>
    <Provider store={ store }>
      <MainScene/>
    </Provider>
  </BrowserRouter>
);