import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';

import { Logo } from 'ui/logo';

export const HeaderLogo = () =>
  <LinkContainer to="/">
    <Navbar.Brand href="/" className="me-4">
      <Logo className="me-2"/>
      <span>{ process.env.REACT_APP_NAME }</span>
    </Navbar.Brand>
  </LinkContainer>