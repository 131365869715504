import enFlat from 'cartzilla/assets/img/flags/en-us.png';
// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import plFlag from 'assets/pl-flag.png';
import Urls from 'cls/Urls';

interface Props {
}

interface State {
}

export default class FooterScene extends React.Component<Props, State> {

  render() {
    const year = new Date().getFullYear();

    return (
      <footer className="bg-darker pt-3 pt-sm-4 pt-md-5 pb-3 pb-sm-4 ps-4 mt-4 mb-5 mb-lg-3">
        <Container>
          <Row>
            <Col xs={ 12 } md={ 4 } className="d-flex flex-row align-content-center justify-content-start">
              <div className="text-nowrap mb-2">
                  <span className="d-inline-block align-middle mt-n1 me-3 mb-0 text-light h3">
                    { process.env.REACT_APP_NAME }
                  </span>
                <Dropdown className="btn-group disable-autohide">
                  <Dropdown.Toggle variant="outline-light" size="sm" className="px-2">
                    <img
                      className="me-2" width={ 20 } height={ 20 } src={ plFlag } alt={ process.env.REACT_APP_PL_LONG }
                    />
                    { process.env.REACT_APP_PL }
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <img
                        className="me-2" width={ 20 } height={ 20 } src={ enFlat } alt={ process.env.REACT_APP_EN_LONG }
                      />
                      { process.env.REACT_APP_EN_LONG }
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <img
                        className="me-2" width={ 20 } height={ 20 } src={ plFlag } alt={ process.env.REACT_APP_PL_LONG }
                      />
                      { process.env.REACT_APP_PL_LONG }
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col
              xs={ 12 } md={ 8 }
              className="d-flex flex-row align-content-center justify-content-start justify-content-md-end"
            >
              <div
                className="d-flex flex-row flex-wrap justify-content-start align-items-start align-items-sm-center"
              >
                <a
                  className="text-secondary me-4 text-decoration-none"
                  href="#"
                >{ process.env.REACT_APP_TERMS }</a>
                <a
                  className="text-secondary me-4 text-decoration-none" href="#"
                >{ process.env.REACT_APP_PRIVACY_POLICY }</a>
                <a
                  className="text-secondary me-4 text-decoration-none" href={ Urls.HELP } target="_blank"
                  rel="noreferrer"
                >
                  { process.env.REACT_APP_HELP }
                </a>
                <LinkContainer to={ Urls.SEND_FEEDBACK }>
                  <a
                    className="text-secondary me-4 text-decoration-none" href={ Urls.SEND_FEEDBACK }
                  >{ process.env.REACT_APP_CONTACT }</a>
                </LinkContainer>
                <LinkContainer to={ Urls.ABOUT_US }>
                  <a
                    className="text-secondary me-4 text-decoration-none" href={ Urls.ABOUT_US }
                  >{ process.env.REACT_APP_ABOUT_US }</a>
                </LinkContainer>
              </div>
            </Col>
          </Row>
          <div className="fs-8 text-light opacity-50 mt-3 mt-md-0">
            © Brokli { year }. { process.env.REACT_APP_ALL_RIGHTS_RESERVED }&nbsp;
          </div>
        </Container>
      </footer>
    );
  }
}
