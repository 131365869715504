import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';

import Account, { Profile } from 'cls/Account';
import { ActionTypes } from 'cls/redux/actions';
import { StoreDispatch, StoreState } from 'cls/store';
import Urls from 'cls/Urls';

import 'page/header/name/style.css';

interface Props {
}

interface ReduxStateProps {
  account?: Account;
  profile?: string;
}

interface ReduxDispatchProps {
  getAccount: () => void;
  setProfile: (profile: string) => void;
  logout: () => void;
}

interface State {}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {

  constructor(props: Props & ReduxDispatchProps & ReduxStateProps) {
    super(props);
    this.clickProfile = this.clickProfile.bind(this);
    this.logout = this.logout.bind(this);
  }

  clickProfile(profileId: string) {
    const { setProfile } = this.props;
    setProfile(profileId);
  }


  logout() {
    const { logout } = this.props;
    logout();
  }

  render() {
    const { account } = this.props;
    const profile: Profile | undefined = account?.currentProfile;

    return <>
      { account !== undefined &&
        <Dropdown className="ms-3">
          <Dropdown.Toggle as="div">
            &nbsp; { profile?.name } &nbsp;
            <div
              className="icon-profile rounded-circle"
              style={ { backgroundColor: profile?.color } }
              aria-label={ process.env.REACT_APP_ACCOUNT }
            >
              <i className="navbar-tool-icon"/>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" renderOnMount={ true }>
            { account.profiles.filter((x) => x.id !== account?.profile).map((p, pi) => (
              <Dropdown.Item
                key={ `profile-${ pi }` }
                onClick={ () => this.clickProfile(p.id) }
                aria-label={ `${ process.env.REACT_APP_PICK } ${ p.name }` }
              >
                <span style={ { color: p.color } }>⬤</span> &nbsp; { p.name }
              </Dropdown.Item>
            )) }
            <Dropdown.Divider/>
            <LinkContainer to={ Urls.PROFILES }>
              <Dropdown.Item href={ Urls.PROFILES }>{ process.env.REACT_APP_ACCOUNT }</Dropdown.Item>
            </LinkContainer>
            <Dropdown.Item href={ Urls.HELP } target="_blank" rel="noreferrer">
              { process.env.REACT_APP_HELP }
            </Dropdown.Item>
            <Dropdown.Item
              className="cursor-pointer"
              onClick={ () => this.logout() }
            >
              { process.env.REACT_APP_LOGOUT }
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      }
    </>;
  }
}


const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
    account: state.account.account === null ? undefined : state.account.account,
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    getAccount: () => dispatch({ type: ActionTypes.GetAccount }),
    setProfile: (profile: string) => dispatch({ type: ActionTypes.SetProfile, profile }),
    logout: () => dispatch({ type: CoreActionTypes.LogoutUser }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const NameDropdown = () => (
  <ConnectedScene/>
);

export default NameDropdown;
