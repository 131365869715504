import { LinkContainer } from 'react-router-bootstrap';

export const Breadcrumbs = (props: {
  breadcrumbs: { url?: string | undefined, name: string | undefined }[],
}) => {
  return <nav aria-label="breadcrumb" data-bs-theme="dark">
    <ol className="breadcrumb text-light flex-lg-nowrap justify-content-center justify-content-lg-start">
      <li className="breadcrumb-item">
        <LinkContainer to="/">
          <a href="/" className="d-flex align-items-center">
            <i className="czi-home fs-base me-2"/>
            { process.env.REACT_APP_PAGE }
          </a>
        </LinkContainer>
      </li>
      { props.breadcrumbs.map((x) => (
        <li
          className="breadcrumb-item text-nowrap"
          key={ `${ x.name }:${ x.url }` }
          aria-current={ x.url === undefined && "page" }
        >
          { x.url !== undefined ?
            <LinkContainer to={ x.url }>
              <a href={ x.url }>
                { x.name }
              </a>
            </LinkContainer> :
            x.name
          }
        </li>
      )) }
    </ol>
  </nav>;
}