import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from 'cls/store';


interface Props {
  name?: string,
  children: JSX.Element,
}

interface ReduxStateProps {
  flags?: string[];
}

interface ReduxDispatchProps {}

interface State {}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  render() {
    const { children, flags, name } = this.props;
    if (name === undefined || name === "") {
      return children;
    }
    return (
      <>
        { flags !== undefined && flags.indexOf(name) !== -1 && children }
      </>
    )
  }
}

const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
    flags: state.basic.flags === null ? undefined : state.basic.flags,
  });
};

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps,
)(Scene);

const FeatureOn = (props: Props) => (
  <ConnectedScene { ...props } />
);

export default FeatureOn;
