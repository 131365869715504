// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { Button, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Urls from 'cls/Urls';
import ContentPageHeader from 'ui/content-page-header';

const DbSelectScene = () => {
  return <>
    <ContentPageHeader
      title={ process.env.REACT_APP_DATABASE }
      breadcrumbs={ [] }
    />
    <Container className="d-flex flex-column align-items-center">
      <LinkContainer to={ Urls.INGREDIENTS }>
        <Button variant="primary" href={ Urls.INGREDIENTS } size="lg" className="mb-3 pb-5 pt-5 w-100">
          { process.env.REACT_APP_INGREDIENTS }
        </Button>
      </LinkContainer>
      <LinkContainer to={ Urls.RECIPES }>
        <Button variant="primary" href={ Urls.RECIPES } size="lg" className="mb-3 pb-5 pt-5 w-100">
          { process.env.REACT_APP_RECIPES }
        </Button>
      </LinkContainer>
      <LinkContainer to={ Urls.PREMENUS }>
        <Button variant="primary" href={ Urls.PREMENUS } size="lg" className="mb-3 pb-5 pt-5 w-100">
          { process.env.REACT_APP_PLANS }
        </Button>
      </LinkContainer>
    </Container>
  </>;
};

export default DbSelectScene;