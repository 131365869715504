import * as _ from 'underscore';

import ApiImg from 'cls/ApiImg';
import Constants from 'cls/Constants';
import Ingredient from 'cls/Ingredient';
import IngredientsList from 'cls/IngredientsList';
import ManualItem from 'cls/ManualItem';
import Portion from 'cls/Portion';
import Price from 'cls/Price';
import Quantity from 'cls/Quantity';
import RecipeIngredient from 'cls/RecipeIngredient';
import Urls from 'cls/Urls';
import { Measure as MeasureEnum, Owner as ThriftOwner, Q } from 'thriftgen';
import { ManualItem as ThriftManualItem } from 'thriftgen/ManualItem';
import { MenuItem as ThriftMenuItem } from 'thriftgen/MenuItem';
import { Price as ThriftPrice } from 'thriftgen/Price';
import { Recipe as ThriftRecipe } from 'thriftgen/Recipe'
import { Translation as ThriftTranslation } from 'thriftgen/Translation';

export default class Recipe {
  id: string;
  owner: string;
  ownerName: string;
  sources: string[];
  name: string;
  nameGenitive: string;
  pluralName: string;
  pluralNameGenitive: string;
  serving: number;
  count?: Q;
  ingredients: RecipeIngredient[];
  tags: string[];
  category?: string;
  steps: string[];
  variationIds: string[];
  variations: Recipe[];
  comment: string;
  portions: Portion;
  measure: string = "portion";
  manual: ManualItem[];
  price: Price;
  img?: ApiImg;

  type = "recipe";

  constructor(x: ThriftRecipe, is: Ingredient[]) {
    const language = 'pl';
    this.id = x.id;
    this.owner = x.owner || "";
    this.ownerName = x.ownerName || "";
    this.sources = x.sources || [];
    this.name = x.name.get(language)?.sn || "";
    this.nameGenitive = x.name.get(language)?.sg || this.name;
    this.pluralName = x.name.get(language)?.pn || this.name;
    this.pluralNameGenitive = x.name.get(language)?.pg || this.pluralName;
    this.serving = x.serving;
    this.count = x.count || undefined;
    this.ingredients = RecipeIngredient.init(x.ingredients, is);
    this.tags = x.tags || [];
    this.category = x.category;
    this.comment = x.comment;
    this.steps = x.steps.get(language) || [];
    this.variationIds = x.variationIds || [];
    this.variations = [];
    this.portions = new Portion({
      count: this.count !== undefined ? { n: this.count.n, m: this.count.m } : undefined
    });

    if (this.owner === "" || this.owner === undefined) {
      this.owner = "public";
    }
    this.tags.push(`owner_${ this.owner }`);
    this.manual = x.manual.map((x) => new ManualItem(x));
    this.price = new Price(x.price);
  }

  static init = (xs: ThriftRecipe[], is: Ingredient[]) => xs.map((x) => new Recipe(x, is));
  static setRecipes = (xs: Recipe[]) => xs.map((x) => x.setRecipes(xs));

  static empty(): Recipe {
    const profile = localStorage.getItem("profile") || "";
    return new Recipe(new ThriftRecipe({
      id: "new",
      owner: profile,
      ownerName: "",
      sources: [] as string[],
      name: new Map([ [ "pl", new ThriftTranslation({ sn: "", sg: "", pn: "", pg: "" }) ] ]),
      serving: 1,
      ingredients: [],
      tags: [] as string[],
      steps: new Map([ [ "pl", [ '' ] ] ]),
      variationIds: [] as string[],
      category: "",
      comment: "",
      manual: [],
      price: new ThriftPrice({ kcal: 0, keto: 0 }),
      updated: "",
    }), []);
  }

  copy(): Recipe {
    const ingredients = this.ingredients
      .map((x) => x.ingredient)
      .filter((x) => x !== undefined)
      .map((x) => x as Ingredient);
    const recipes = this.ingredients
      .map((x) => x.recipe)
      .filter((x) => x !== undefined)
      .map((x) => x as Recipe);
    const result = new Recipe(this.thrift(), ingredients);
    result.setRecipes(recipes);
    return result;
  }

  thrift(): ThriftRecipe {
    return new ThriftRecipe({
      id: this.id === 'new' ? '' : this.id,
      category: this.category || '',
      comment: this.comment,
      count: this.count,
      ingredients: this.ingredients.map((x) => x.thrift()),
      name: new Map([ [ "pl", new ThriftTranslation({
        sn: this.name, sg: this.nameGenitive, pn: this.pluralName, pg: this.pluralNameGenitive,
      }) ] ]),
      owner: this.owner,
      serving: this.serving,
      sources: this.sources,
      steps: new Map([ [ "pl", this.steps ] ]),
      tags: this.tags,
      variationIds: [],
      ownerName: "",
      manual: this.manual.map((x) => new ThriftManualItem(x)),
      price: this.price.thrift,
      updated: "",
    })
  }

  get defaultQ(): Q {
    return new Q({ n: 1, m: MeasureEnum.COUNT });
  }

  newMenuItem(owner: ThriftOwner, date: string | undefined, q: Quantity | undefined): ThriftMenuItem {
    return new ThriftMenuItem({
      id: '',
      recipeId: this.id,
      owner: owner,
      date: date || '',
      q: q?.q || new Q({ n: 1, m: 1 })
    })
  }

  get isInSeason(): boolean {
    return false;
  }

  static allTags(xs: Recipe[]): string[] {
    return _.chain(xs)
      .map((x) => x.tags)
      .flatten()
      .uniq()
      .value();
  }

  remove(field: string, i: number) {
    if (
      field === 'steps' ||
      field === 'sources' ||
      field === 'manual' ||
      field === 'ingredients'
    ) {
      this[field].splice(i, 1);
    }
    if (field === 'variationIds') {
      this.variationIds.splice(i, 1);
      this.variations.splice(i, 1);
    }
  }

  edit(field: string, value: string, i?: number) {
    if (
      field === 'name' ||
      field === 'nameGenitive' ||
      field === 'pluralName' ||
      field === 'pluralNameGenitive' ||
      field === 'comment' ||
      field === 'category'
    ) {
      this[field] = value;
    }
    if (
      field === 'serving'
    ) {
      this[field] = parseInt(value);
    }
    if (
      field === 'steps' ||
      field === 'sources'
    ) {
      if (i === undefined) { return; }
      this[field][i] = value;
    }
    if (
      field === 'manual'
    ) {
      if (i === undefined) { return; }
      this[field][i].text = value;
    }
    if (field === 'category' && this.tags.every((x) => x !== value)) {
      this.tags.push(value);
    }
  }

  addVariation(variation: Recipe) {
    this.variations.push(variation);
    this.variationIds.push(variation.id);
  }

  addRecipeIngredient(ri: RecipeIngredient) {
    this.ingredients.push(ri);
  }

  toggleTag(tag: string) {
    const index = this.tags.indexOf(tag, 0);
    if (index === -1) {
      this.tags.push(tag);
    } else {
      this.tags.splice(index, 1);
    }
  }

  setRecipes(xs: Recipe[]) {
    this.ingredients.map((x) => x.setRecipes(xs));
    this.variations = this.variationIds
      .map((v) => xs.find((x) => x.id === v))
      .filter((x): x is Recipe => x !== undefined);
  }

  get url(): string {
    return Urls.RECIPE_ID(this.id);
  }

  searchMatch(text: string): boolean {
    const search = (this.id + this.name).toLocaleLowerCase();
    return search.includes(text.toLowerCase());
  }

  hasIngredient(ingredientId: string): boolean {
    return IngredientsList.recipe(this, false)
      .find((x) => x.ingredientId === ingredientId) !== undefined;
  }

  get measureOptions(): string[] {
    return Array(15)
      .fill(0)
      .map((_, i) => (i + 1).toString(10));
  }

  hasTag(tag: string): boolean {
    const x = this.ingredients.find((x) => x.tags.indexOf(tag) !== undefined);
    return x !== undefined;
  }

  hasFish(): boolean {
    return this.hasTag(Constants.TAG_FISH);
  }

  hasMeat(): boolean {
    return this.hasTag(Constants.TAG_MEAT);
  }

  hasSeafood(): boolean {
    return this.hasTag(Constants.TAG_SEAFOOD);
  }

  hasFruits(): boolean {
    return this.hasTag(Constants.TAG_FRUIT);
  }

  hasEggs(): boolean {
    return this.hasTag(Constants.TAG_EGG);
  }

  isVegetarian(): boolean {
    return !this.hasFish() && !this.hasSeafood() && !this.hasMeat();
  }

  getSimilarRecipes(recipes: Recipe[], num: number): Recipe[] {
    return _.chain(recipes)
      .sortBy((x) => Recipe.similarity(this, x))
      .reverse()
      .take(num)
      .value();

  }

  static similarity(x1: Recipe, x2: Recipe): number {
    const categoryPoints = 10;
    const meatPoints = 10;
    const fishPoints = 10;
    const seafoodPoints = 10;
    const vegetarianPoints = 10;
    const fruitPoints = 10;
    const eggPoints = 10;

    let result = 0;

    if (x1.category === x2.category) {
      result = result + categoryPoints;
    }
    if (x1.hasFish() && x2.hasFish()) {
      result = result + fishPoints;
    }
    if (x1.hasSeafood() && x2.hasSeafood()) {
      result = result + seafoodPoints;
    }
    if (x1.hasMeat() && x2.hasMeat()) {
      result = result + meatPoints;
    }
    if (x1.hasFruits() && x2.hasFruits()) {
      result = result + fruitPoints;
    }
    if (x1.hasEggs() && x2.hasEggs()) {
      result = result + eggPoints;
    }
    if (x1.isVegetarian() && x2.isVegetarian()) {
      result = result + vegetarianPoints;
    }
    return result;
  }

  static recipesWithIngredient(recipes: Recipe[], ingredient: string) {
    return recipes.filter((x) => x.hasIngredient(ingredient));
  }
}
