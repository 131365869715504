import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux'

import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';

export const SidebarToggler = () => {
  const dispatch = useDispatch()

  return <Button
    size="lg"
    className="btn-icon fs-xl border-0 rounded-circle d-lg-none"
    aria-label="Toggle nav sidebar"
    onClick={ () => dispatch({ type: CoreActionTypes.TfToggle, name: "left-nav" }) }
    variant="outline-secondary"
  >
    <i className="ci-menu"></i>
  </Button>;
}
