import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Urls from 'cls/Urls';
import { Logo } from 'ui/logo';


export const Header = () => {
  return (
    <header className="bg-light box-shadow-sm fixed-top">
      <Navbar bg="light" expand="lg" className="bg-light sticky-top">
        <Container fluid={ true }>
          <LinkContainer to="/">
            <Navbar.Brand href="/" className="me-4 d-flex align-items-center p-0">
              <Logo className="me-2"/>
              <span style={ { "fontSize": "2rem" } }>Brokli</span>
            </Navbar.Brand>
          </LinkContainer>
          <div>
            <LinkContainer to={ Urls.LOGIN } className="d-lg-none me-3">
              <a href={ Urls.LOGIN } className="btn btn-primary" type="button">
                { process.env.REACT_APP_B_LOGIN }
              </a>
            </LinkContainer>
            <Navbar.Toggle aria-controls="navbarNavDropdown"/>
          </div>
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav>
              <LinkContainer to="/" className="d-md-none d-lg-block">
                <Nav.Link href="/">{ process.env.REACT_APP_MAIN_PAGE }</Nav.Link>
              </LinkContainer>
              <LinkContainer to={ Urls.FEATURES }>
                <Nav.Link href={ Urls.FEATURES }>{ process.env.REACT_APP_FEATURES }</Nav.Link>
              </LinkContainer>
              <LinkContainer to={ Urls.PRICING }>
                <Nav.Link href={ Urls.PRICING }>{ process.env.REACT_APP_PRICING }</Nav.Link>
              </LinkContainer>
              <LinkContainer to={ Urls.ABOUT_US }>
                <Nav.Link href={ Urls.ABOUT_US }>{ process.env.REACT_APP_ABOUT_US }</Nav.Link>
              </LinkContainer>
              <LinkContainer to={ Urls.REGISTER } className="d-lg-none">
                <a href={ Urls.REGISTER } className="btn btn-outline-primary me-2" type="button">
                  { process.env.REACT_APP_B_REGISTER }
                </a>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
          <LinkContainer to={ Urls.REGISTER } className="d-none d-lg-block">
            <a href={ Urls.REGISTER } className="btn btn-outline-primary me-2" type="button">
              { process.env.REACT_APP_B_REGISTER }
            </a>
          </LinkContainer>
          <LinkContainer to={ Urls.LOGIN } className="d-none d-lg-block">
            <a href={ Urls.LOGIN } className="btn btn-primary my-2 my-sm-0" type="button">
              { process.env.REACT_APP_B_LOGIN }
            </a>
          </LinkContainer>
        </Container>
      </Navbar>
    </header>
  );
}